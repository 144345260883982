import React, { Component } from 'react'
import { notifications } from '../../../stores'

import { check, save, defaultRows, rowLabels } from './importerByText'
// import { check, save, defaultRows, rowLabels } from './importerById'

export default class Importer extends Component {
  state = {
    rows: defaultRows, // map na
    availableRows: []
  }

  componentDidMount() {
    const file = this.props.file
    return this.XlsfileToJson(file).then(imported => {
      const availableRowsIndex = {}
      for (let lineCounter = 0; lineCounter < imported.length; lineCounter++) {
        for (const rowName in imported[lineCounter]) {
          availableRowsIndex[rowName] = true
        }
      }
      const rows = { ...this.state.rows }
      if (!availableRowsIndex.id) {
        rows.id = null
      }
      if (!availableRowsIndex.path) {
        rows.path = null
      }
      if (!availableRowsIndex.type) {
        rows.type = null
      }
      const availableRows = Object.keys(availableRowsIndex)
      this.setState({ imported, rows, availableRows })
    })
  }

  XlsfileToJson(file) {
    return new Promise((resolve, reject) => {
      import('xlsx')
        .then(XLSX => {
          const reader = new FileReader()
          reader.onload = e => {
            const data = e.target.result
            const workbook = XLSX.read(data, { type: 'binary' })
            const firstSheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[firstSheetName]
            const converted = XLSX.utils.sheet_to_json(worksheet)
            resolve(converted)
          }
          reader.onerror = reject
          reader.onabort = reject
          reader.readAsBinaryString(file)
        })
        .catch(reject)
    })
  }

  import = async () => {
    const { rows, imported } = this.state
    const { experienceId, onSave, originalTexts } = this.props
    const problems = await check({ rows, originalTexts, imported })
    console.log(problems) // On ne va pas tout bloquer si un texte est manquant. On affihce juste le problème dans la console.

    this.setState({ importing: true })
    await save({ rows, originalTexts, imported, experienceId, onSave })
      .catch(error => {
        const id = Date.now()
        notifications.set(id, { id, type: 'danger', title: "Impossible d'intégrer ce fichier", content: error })
      })
      .finally(() => this.setState({ importing: false }))
  }

  render() {
    const { rows, availableRows, importing } = this.state

    return (
      <form
        onSubmit={e => {
          e.preventDefault()
          const ok = Object.keys(defaultRows).reduce((prec, val) => prec && rows[val], true)
          if (ok) {
            this.import()
              .then(() => this.setState({ mode: 'table' }))
              .catch(error => console.error(error))
          }
          return e.preventDefault()
        }}
      >
        {Object.keys(rowLabels).map(key => {
          const label = rowLabels[key]
          return (
            <div className="form-group" key={key}>
              <label>{label}</label>
              <select
                className="form-control"
                name={key}
                onChange={e => {
                  console.log('change to ', key)
                  this.setState({
                    rows: {
                      ...rows,
                      [key]: e.target.value
                    }
                  })
                }}
                value={rows[key]}
              >
                <option />
                {availableRows.map(rn => (
                  <option key={rn} val={rn}>
                    {rn}
                  </option>
                ))}
              </select>
            </div>
          )
        })}
        {importing && (
          <button type="submit" className="btn btn-primary btn-block" disabled>
            <i className="fa fa-gear fa-spin" /> Importation en cours
          </button>
        )}
        {!importing && (
          <button type="submit" className="btn btn-primary btn-block">
            Importer
          </button>
        )}
      </form>
    )
  }
}
