export default function jsonToArray(path, json) {
  path = path || ''
  let lines = []
  const character = json.character || '' // pour les voix lues dans les conversations

  if (json.tourinsoftId || json.deleted) return []

  for (const key in json) {
    const value = json[key]

    // Série de conditions où un élément (valeur ou objet) ne doit pas étre traduit.
    if (!value) continue // Si pas de valeur.
    if (['cases', 'defaultActions'].includes(key)) continue // Supprime les traductions du module Switch.
    if (['actions'].includes(key)) continue // Supprime les traductions des actions.
    if (key === 'mode') continue // Pas de traduction sur le mode du Cluedo.
    if (key === 'key' && path.startsWith('ratings')) continue // Pour ne plus traduire les clés des notations.
    if (key === 'name' && path.startsWith('targets')) continue // Nom des cibles RA.
    // Grosse liste de cas que je n'ai pas envie de simplifier/expliquer...
    if (
      [
        'character',
        'characters',
        'authorizations',
        'theme',
        'timers',
        'type',
        'uid',
        '_id',
        'position',
        'size',
        'updatedBy',
        'updatedAt',
        'createdAt',
        'color',
        'scoreType',
        'file',
        'dropType',
        'internalName',
        'locale',
        'notes',
        'tourinsoftId',
        'inputType',
        'beacons',
        'align'
      ].indexOf(key) >= 0
    ) {
      continue
    }

    // Si la valeur est une chaine de caractères, on ne traduit pas certains cas (ID, URL).
    if (typeof value === 'string') {
      if (value.match(/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/) || value.match(/^[a-f0-9]{24}$/)) continue
      if (value.match(/^https:\/\/.+$/)) continue

      lines.push({ path: path ? path + '°|°' + key : key, text: value, character }) // Traduction de l'élément.
      continue
    }

    // Si value est un objet ou un tableau, la fonction devient récursive pour aller plus en profondeur.
    if (Array.isArray(value) || typeof value === 'object') {
      lines = lines.concat(jsonToArray(path ? path + '°|°' + key : key, value))
    }
  }
  return lines
}
